import './App.css';
import YouTube from './Media';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"; 
import Play from './Play';

import { Provider } from 'react-redux'
import store from './store'



function App() {
  return (
    <div className="Video">
      <Provider store={store}>
        <Router>
            <Routes>
              <Route path="/account/video-on-demand/player/*" element={<Play />}>
              </Route>
              <Route path="/account/video-on-demand" element={<YouTube />}>
              </Route>
            </Routes>
        </Router>
      </Provider>
    </div>
  );
}



export default App;
