import React from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

// This imports the functional component from the previous sample.


const Play = () => {
  const playerRef = React.useRef(null);
  const navigate = useNavigate();
  const file = useSelector((state) => {
    return state.play.media;
  });

  React.useEffect(()=>{
    if(file.videosrc === '')
    {
      navigate('/account/video-on-demand')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])






  return (
    <div class="container">
      <iframe frameBorder="0" className='frame'  src={`${file?.videosrc}`} loading="lazy" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowFullScreen={true} />
    </div>
  )
};

export default Play;