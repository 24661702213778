import { Button, DialogActions, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Play from './Play';
import {  useDispatch } from 'react-redux'
import { updatePlay } from './slices/playSlice';

export default function PlayDialog( props) {

  const {open} = props;
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(updatePlay(false))
  }

    
  return (
    <div>

      <Dialog open={open} 
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            className="video-dialog"
        >
        <DialogContent className="video-dialog">
          <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={1} sx={{mt:1}}>
          <Grid item xs={12} className="video-box">
              <Play/>        
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}