import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { AppBar, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Toolbar } from '@mui/material';
//import { useNavigate } from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSelector, useDispatch } from 'react-redux'
import { updateMedia } from './slices/librarySlice';
import { updatePlay } from './slices/playSlice';
import { Container } from '@mui/system';
import PlayDialog from './PlayDialog';


function Media(props) {

  const [loading, setLoading] = React.useState(props.loading);
  const library = useSelector((state) => {
    console.log("Library state", state);
    return state.library.media;
  }) || [];
  
  const file = useSelector((state) => {
    return state.play.media;
  });
  const dispatch = useDispatch()

  React.useEffect(() =>{
    onRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefresh = () => {
    try 
    {
      setLoading(true);

      fetch("/account/do-ajax-get-video-libary", {
        method: "POST",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
        body: window.security_name + '=' + window.security_token,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": 'XMLHttpRequest',
              "X_REQUESTED_WITH": 'xmlhttprequest'
          }
      })
      .then(response => response.json())
      .then(data =>{
        if(data.status === 'OK')
        {
            const media = data.resources.map( m => {
                const x = {
                  id: m.id,
                  src: m.preview,
                  videosrc: m.stream,
                  title: m.title,
                  description: m.description
                };
                return x;
            });
            dispatch(updateMedia(media))
        }
        else
        {
          dispatch(updateMedia([]))
        }
        console.log('Library', data);
        setLoading(false);
      })
      .catch((e)=> {
        setLoading(false);
        console.log('Error', e)
      });
    }
    catch(e)
    {
      setLoading(false);
      console.log('Error', e);
    }   
  }


  const onVideoSelect = (item) => {
    dispatch(updatePlay(item))
    //navigate('/account/video-on-demand/player/'); 
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg">
          <Box sx={{mt:1, mb: 1}} className="navigation" >
              <AppBar position="sticky" className="nav">
                <Toolbar>
                  <Typography variant="h2" className="title">
                      On demand classes
                  </Typography>
                    
                </Toolbar>
              </AppBar>
          </Box>



          <Box sx={{mt:2, mb: 4}} className="video-manager-body">
              <Grid container wrap="wrap"  alignItems="stretch"  justifyContent="flex-start" spacing={3}>
                {
                  
                    loading === false && library.length === 0 &&
                    <Grid item xs={12}>
                        <Grid container   direction="row" justifyContent="center" alignItems="center" spacing={2} className="no-lib-box">
                            <Grid item xs={12}>
                                <FileCopyIcon  className='no-lib'/> 
                            </Grid>
                            <Grid item xs={12}>
                            <h2 className='no-lib-text'>No videos</h2>
                            </Grid>
                        </Grid>
                    </Grid>
                  
                }
                {(loading ? Array.from(new Array(8)) : library).map((item, index) => (
                   <Grid item xs={6}  md={3}  style={{display: 'flex'}}>
                  {  item ? (<Card   onClick={()=>{onVideoSelect(item)}} variant="outlined">
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="140"
                                  image={item.src}
                                />
                                <CardContent>
                                  <Typography gutterBottom variant="h6" component="div">
                                    {item.title}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                      {item.description}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                              <CardActions>
                                <Button size="small" color="primary"  onClick={()=>{onVideoSelect(item)}}>
                                  Play
                                </Button>
                              </CardActions>
                            </Card>
                            ):(
                              <Skeleton variant="rectangular" width={"100%"} height={118} />
                            )

                   }
                   </Grid> 
                    
                ))}
              </Grid>
          </Box>
      </Container>

      <PlayDialog open={!!file}/>

    </React.Fragment>

  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};

export default function YouTube() {
  return (
    <Box sx={{ overflow: 'hidden' }} className="Video">
      <Media loading={false} />
    </Box>
  );
}
